import axios from 'axios';

export function isLoggedIn() {
    let token = getToken()
    return token !== null;
}

export function validateToken() {
    let token = getToken()
    return axios.post(process.env.REACT_APP_API_ENDPOINT + "token/validate", {token: token}).catch(() => {
        clearSession();
    })
}

export function clearSession() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('expiration');
    window.localStorage.removeItem('permissions');
    window.localStorage.removeItem('username');
}

export function getToken() {
    return window.localStorage.getItem('token');
}

export function getUsername() {
    return window.localStorage.getItem('username');
}

export function hasPermission(perm_item) {
    let items = JSON.parse(window.localStorage.getItem('permissions'));
    return perm_item in items;
}
