import React from "react";
import './Navigation.scss'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from 'axios';
import {clearSession, getToken} from "./Session";


function GetToken({ location }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    executeRecaptcha('logout').then((recaptcha_token) => {
        let data = {
            token: getToken(),
            recaptcha: recaptcha_token
        }
        axios.post(process.env.REACT_APP_API_ENDPOINT + "token/revoke", data, )
            .then( (resp) => {
                clearSession();

                window.location = '/';
            }).catch((err) => {
            if (err.response) {
                if (err.response.data.error === 'token_verification_failed' ) {
                    clearSession();
                    window.location = '/';
                }
                else
                    window.location = '/error?error=' + err.response.data.error;
            }
            else
                window.location = '/error?error=revoking_token_failed';
        })
    }).catch(() => {
        window.location = '/error?error=captcha_verification_failed';
    })

    return (
        <div id="container" className="bg-light container-fluid text-center">
            <div className="row">
                <div className="col-12">
                    <div className="bg-white rounded shadow">
                        <p>로그아웃 중입니다...</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetToken;