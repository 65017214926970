import React from "react";
import { Route, Redirect } from 'react-router-dom';
import {hasPermission, isLoggedIn} from './Session'

function ACLRoute({ acl_item, component: Component, render, ...rest }) {
    if (!isLoggedIn())
        return <Redirect to='/login' />
    else if (acl_item !== undefined && !hasPermission(acl_item))
        return <Redirect to={{pathname: '/mypage', state:{error: 'no_permission'}}} />
    else
        return <Route render={(props) => <Component {...props} /> } />
}


export default ACLRoute;