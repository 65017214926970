import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import React from "react";
import {LinkContainer} from 'react-router-bootstrap';
import './Navigation.scss'
import { isLoggedIn, getUsername } from "./Session";
import {NavDropdown} from "react-bootstrap";

function Navigation() {
    let loggedIn = <LinkContainer to="/login"><Nav.Link role="button">로그인</Nav.Link></LinkContainer>;

    if (isLoggedIn())
        loggedIn =
            <NavDropdown title={"Logged in as " + getUsername()} id="navbar-account-dropdown">
                <NavDropdown.Item><LinkContainer to="/mypage"><Nav.Link role="button">Mypage</Nav.Link></LinkContainer></NavDropdown.Item>
                <NavDropdown.Item><LinkContainer to="/logout"><Nav.Link role="button">Logout</Nav.Link></LinkContainer></NavDropdown.Item>
            </NavDropdown>;
    return (
        <Navbar collapseOnSelect={true} bg="dark" expand="lg" variant="dark" sticky="top" fixed="top">
            <Navbar.Brand>NUMAZU.info</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-menus" />
            <Navbar.Collapse id="navbar-menus">
                <Nav className="mr-auto" id='navbar-menu-left'>
                    <LinkContainer to="/"><Nav.Link role="button">검색</Nav.Link></LinkContainer>
                    <LinkContainer to="/category"><Nav.Link role="button">카테고리</Nav.Link></LinkContainer>
                </Nav>
                <Nav className="mr-auto justify-content-end" id='navbar-menu-right'>
                    {loggedIn}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;