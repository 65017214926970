import React from 'react';
import Login from "./Login";
import NewUserCaptcha from "./NewUser";
import GetToken from "./GetToken";
import './App.css';
import Navigation from "./Navigation";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';
import Footer from "./Footer";
import MyPage from "./MyPage";
import ACLRoute from './ACLRoute'
import Search from "./Search";
import Logout from "./Logout"
import GeneralError from "./GeneralError";
import EditPage from "./Editor";


function App() {
    return (
        <div className="App">
            <Router>
                <LastLocationProvider>
                    <Navigation/>
                    <div id='content'>
                        <Switch>
                            <Route exact path="/" component={EditPage}/>
                            <Route path="/login" component={Login} />
                            <Route path="/new_user" component={NewUserCaptcha}/>
                            <Route path="/get_token" component={GetToken}/>
                            <Route path="/error" component={GeneralError} />
                            <ACLRoute path="/mypage" component={MyPage} />
                            <ACLRoute path="/logout" component={Logout} />
                        </Switch>
                    </div>
                    <Footer/>
                </LastLocationProvider>
            </Router>
        </div>
  );
}

export default App;
