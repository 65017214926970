import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {getUsername, getToken, hasPermission} from "./Session";
import axios from 'axios';
import Alert from "react-bootstrap/Alert";

class MyPage extends Component{
    constructor(props) {
        super(props);
        if (props.location.state !== undefined)
            this.state = props.location.state;
        else
            this.state = {};
        if (!('error' in this.state))
            this.state.error = '';
        this.state.nickname = getUsername();
        this.state.email = ' ';
        this.state.user_level = 0;
        this.state.reputation = 0;
    }

    componentDidMount() {
        const setAccountInfo = (data) => {
            this.setState(data)
        }

        axios.post(process.env.REACT_APP_API_ENDPOINT + "account/get_user_data", {token: getToken()})
            .then((resp) => {
                setAccountInfo(resp.data.user_data);
            }).catch((e) => {
            if (e.response)
                setAccountInfo({'error': e.response.data.error});
            else
                setAccountInfo({'error': 'error_requesting_server'});
        })
    }

    render() {
        let error_msg = '';
        if (this.state.error !== '') {
            if (this.state.error === 'token_verification_failed')
                error_msg = <div className="col-12"><Alert variant="danger">로그인 토큰 검증에 실패했습니다.</Alert></div>
            else if (this.state.error === 'failed_to_find_user')
                error_msg = <div className="col-12"><Alert variant="danger">사용자를 찾지 못했습니다.</Alert></div>
            else if (this.state.error === 'no_permission')
                error_msg = <div className="col-12"><Alert variant="danger">해당 동작에 대한 권한이 없습니다. 아래에서 권한을 확인해주세요.</Alert></div>
            else if (this.state.error === 'revoking_token_failed')
                error_msg = <div className="col-12"><Alert variant="danger">토큰 폐기에 실패해 로그아웃되지 않았습니다.</Alert></div>
            else
                error_msg = <div className="col-12"><Alert variant="danger">에러가 발생했습니다.</Alert></div>
        }
        return (
            <div id="container" className="container-fluid text-center bg-light">
                <div className="row">
                    {error_msg}
                    <div className="col-12">
                        <Card className="rounded shadow">
                            <Card.Title>
                                사용자 정보
                            </Card.Title>
                            <Card.Text>
                                현재 계정 정보는 다음과 같습니다.
                            </Card.Text>
                        </Card>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Card className="rounded shadow text-left" style={{padding: 0}}>
                            <Card.Header>Nickname</Card.Header>
                            <Card.Body>
                                <Card.Text id='nickname'>
                                    {this.state.nickname}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Card className="rounded shadow text-left" style={{padding: 0}}>
                            <Card.Header>E-mail</Card.Header>
                            <Card.Body>
                                <Card.Text id='email'>
                                    {this.state.email}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Card className="rounded shadow text-left" style={{padding: 0}}>
                            <Card.Header>User permission level</Card.Header>
                            <Card.Body>
                                <Card.Text id='user_level'>
                                    {this.state.user_level}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <Card className="rounded shadow text-left" style={{padding: 0}}>
                            <Card.Header>User reputation</Card.Header>
                            <Card.Body>
                                <Card.Text id='reputation'>
                                    {this.state.reputation}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}


export default MyPage;