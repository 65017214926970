import 'codemirror/lib/codemirror.css';
import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { Editor } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/i18n/ko-kr'
import '@toast-ui/editor/dist/toastui-editor.css'
import Button from "react-bootstrap/Button";

class EditPage extends React.Component {
    editorRef = React.createRef();

    handleClick = () => {
        this.editorRef.current.getInstance().exec('Bold');
    };

    render() {
        return (
            <div id="container" className="container-fluid text-center bg-light">
                <div className="row">
                    <div className="col-12">
                        <Card className="rounded shadow">
                            <Card.Title>
                                <Form.Control type="text" placeholder="장소명/상호 입력" name=""/>
                            </Card.Title>
                            <Card.Body style={{padding: 0}}>
                                <Editor
                                    previewStyle="tab"
                                    height="400px"
                                    initialEditType="wysiwyg"
                                    initialValue="todokete setsunasaniwa"
                                    language="ko-KR"
                                    hideModeSwitch={true}
                                    ref={this.editorRef}
                                    toolbarItems={[
                                        'heading',
                                        'bold',
                                        'italic',
                                        'strike',
                                        'divider',
                                        'hr',
                                        'quote',
                                        'divider',
                                        'ul',
                                        'ol',
                                        'task',
                                        'indent',
                                        'outdent',
                                        'divider',
                                        'table',
                                        'image',
                                        'link',
                                        'divider'
                                    ]}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditPage;