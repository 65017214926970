import React from 'react';
import queryString from'query-string';
import Alert from "react-bootstrap/Alert";
import {Link} from "react-router-dom";

function GeneralError({ location }) {
    const query = queryString.parse(location.search);
    const hasError = 'error' in query && 'success';
    let alert_msg = null;
    if (!hasError)
        query['error'] = 'wrong_attempt';

    if (query['error'] === 'no_permission') {
        alert_msg = <Alert variant="danger">권한이 없습니다</Alert>;
    }
    else if (query['error'] === 'token_verification_failed') {
        alert_msg = <Alert variant="danger">로그인 토큰 검증에 실패했습니다</Alert>;
    }
    else if (query['error'] in ['wrong_attempt', 'no_required_args', 'dictionary_required']) {
        alert_msg = <Alert variant="danger">잘못된 요청입니다</Alert>
    }
    else if (query['error'] === 'revoking_token_failed') {
        alert_msg = <Alert variant="danger">로그아웃에 실패했습니다</Alert>
    }
    else if (query['error'] === 'new_user_fetching_profile_failed') {
        alert_msg = <Alert variant="danger">요청 시간이 초과되었습니다.</Alert>
    }else if (query['error'] === 'captcha_verification_failed') {
        alert_msg = <Alert variant="danger">reCAPTCHA 인증에 실패했습니다</Alert>
    }
    else {
        alert_msg = <Alert variant="danger">예상치 못한 문제가 발생했습니다</Alert>
    }
    return (
        <div id="container" className="bg-light container-fluid text-center">
            <div className="row">
                <div className="col-12">
                    <div className="bg-white rounded shadow">
                        {alert_msg}
                        <Link to="/" role="button" className="btn btn-outline-dark">홈 화면으로 돌아가기</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default GeneralError;