import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <footer className="footer">
            7th Center Maki Nishikino, 5th Center You Watanabe, 4th Center Rina Tennoji
        </footer>
    )
}

export default Footer;